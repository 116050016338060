const majors = [
  {
    path: '/majors',
    name: 'majors',
    component: () => import('@/views/majors/Majors.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'MajorsAdmin',
    },
  },
]
export default majors
