import repo from '@/repositories'

const repositories = {
  importcbt: repo.ImportCbtRepository,
}
export default {
  import(service, uuid, data) {
    try {
      return repositories[service.toLowerCase()].import(uuid, data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has import method` })
    }
  },
}
