import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/subject'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(subject, uuid) {
    return http.put(`${resource}/${uuid}`, subject)
  },
  add(subject) {
    return http.post(`${resource}`, subject)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
