import repo from '@/repositories'

const repositories = {
  importstudent: repo.ImportStudentRepository,
  importemployee: repo.ImportEmployeeRepository,
}

export default {
  import(service, data) {
    try {
      return repositories[service.toLowerCase()].import(data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has import method` })
    }
  },
}
