const dashboard = [
  {
    path: '/dashboards/school',
    name: 'dashboard-school',
    component: () => import('@/views/dashboards/school/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardSchool',
    },
  },
  {
    path: '/dashboards/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboards/admin/Admin.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/book-puchase-history',
    name: 'book-purchase-history',
    component: () => import('@/views/dashboards/admin/BookPurchaseHistory.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-school',
    name: 'most-active-school',
    component: () => import('@/views/dashboards/admin/MostActiveSchool/MostActiveSchool.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-school/:uuid/detail',
    name: 'most-active-school-detail',
    component: () => import('@/views/dashboards/admin/MostActiveSchool/MostActiveSchoolDetail.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-school/:uuid/detail-book-purchase',
    name: 'most-active-school-detail-book-purchase',
    component: () => import('@/views/dashboards/admin/MostActiveSchool/MostActiveSchoolDetailBookPurchase.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-school/:uuid/detail-active-student',
    name: 'most-active-school-detail-active-student',
    component: () => import('@/views/dashboards/admin/MostActiveSchool/MostActiveSchoolDetailActiveStudent.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-school/:uuid/detail-active-teacher',
    name: 'most-active-school-detail-active-teacher',
    component: () => import('@/views/dashboards/admin/MostActiveSchool/MostActiveSchoolDetailActiveTeacher.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-teacher',
    name: 'most-active-teacher',
    component: () => import('@/views/dashboards/admin/MostActiveTeacher/MostActiveTeacher.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/most-active-student',
    name: 'most-active-student',
    component: () => import('@/views/dashboards/admin/MostActiveStudent/MostActiveStudent.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin/favorite-book',
    name: 'favorite-book',
    component: () => import('@/views/dashboards/admin/FavoriteBook.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/teacher',
    name: 'dashboard-teacher',
    component: () => import('@/views/dashboards/teacher/Teacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardTeacher',
    },
  },
  {
    path: '/dashboards/student',
    name: 'dashboard-student',
    component: () => import('@/views/dashboards/student/Student.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardStudent',
    },
  },
  {
    path: '/dashboards/headmaster',
    name: 'dashboard-headmaster',
    component: () => import('@/views/dashboards/headmaster/Headmaster.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadMaster',
    },
  },
  {
    path: '/dashboards/hod',
    name: 'dashboard-hod',
    component: () => import('@/views/dashboards/hod/Headdepartment.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadDepartment',
    },
  },
]
export default dashboard
