import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/export-attendance-student'

export default {

  printRecap(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
}
