import repo from '@/repositories'

export default {
  add(answer, uuid) {
    try {
      return repo.AnswerRepository.add(answer, uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${answer} service doesn't has add method` })
    }
  },
}
