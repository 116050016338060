import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/school-fund'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(fund) {
    return http.post(`${resource}`, fund)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(fund, uuid) {
    return http.put(`${resource}/${uuid}`, fund)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },

}
