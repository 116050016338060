import { mdiCardAccountDetailsOutline } from '@mdi/js'

export default {
  referenciesschool: {
    title: 'Referency',
    to: 'referency-list',
    icon: mdiCardAccountDetailsOutline,
    resource: 'Referencies',
    action: 'read',
  },
}
