const eraport = [
  {
    path: '/eraport-school',
    name: 'eraport-school',
    component: () => import('@/views/eraport/school/Eraport.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'EraportSchool',
    },
  },
  {
    path: '/eraport-teacher',
    name: 'eraport-teacher',
    component: () => import('@/views/eraport/teacher/Eraport.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'EraportTeacher',
    },
  },
]
export default eraport
