import repositories from '@/repositories'

export default {
  login(data) {
    return repositories.AuthRepository.login(data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  logout() {
    return repositories.AuthRepository.logout().then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  googleLogin(token) {
    return repositories.AuthRepository.googleLogin(token).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  resetPassword(token, data) {
    return repositories.AuthRepository.resetPassword(token, data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  forgotPassword(data) {
    return repositories.AuthRepository.forgotPassword(data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  oauthCallback(data) {
    return repositories.AuthRepository.oauthCallback(data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  checkUsername(data) {
    return repositories.AuthRepository.checkUsername(data).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
}
