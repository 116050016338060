const school = [
  {
    path: '/school-superadmin',
    name: 'school-superadmin-list',
    component: () => import('@/views/school/superadmin/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolAdmin',
    },
  },
  {
    path: '/school-admin',
    name: 'school-admin-list',
    component: () => import('@/views/school/admin/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolSchool',
    },
  },
]
export default school
