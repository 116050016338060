import repositories from '@/repositories'

export default {
  list(params = {}) {
    return repositories.NotificationsRepository.list(params).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  get(uuid) {
    return repositories.NotificationsRepository.get(uuid).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  add(notif) {
    return repositories.NotificationsRepository.add(notif).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  send(notif) {
    return repositories.NotificationsRepository.send(notif).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  sendAll(notif) {
    return repositories.NotificationsRepository.sendAll(notif).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  update(notif, uuid) {
    // eslint-disable-next-line no-underscore-dangle
    notif._method = 'PATCH'

    return repositories.NotificationsRepository.update(notif, uuid).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  destroy(uuid) {
    return repositories.NotificationsRepository.destroy(uuid).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
}
