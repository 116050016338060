import { mdiChartPie } from '@mdi/js'

export default {
  title: 'Dashboard Guru',
  icon: mdiChartPie,
  resource: 'DashboardTeacher',
  action: 'read',
  to: 'dashboard-teacher',
}

// export default [
//   {
//     subheader: 'Main Menu',
//   },
//   {
//     title: 'Dashboard Admin',
//     icon: mdiChartPie,
//     resource: 'Dashboard',
//     action: 'read',
//     to: 'dashboard-admin',
//     // children: [
//     //   {
//     //     title: 'Student',
//     //     to: { name: 'dashboard-student' },
//     //     resource: 'DashboardStudent',
//     //     action: 'read',
//     //   },
//     // ],
//   },
//   {
//     title: 'Dashboard Student',
//     icon: mdiChartPie,
//     resource: 'DashboardStudent',
//     action: 'read',
//     to: 'dashboard-student',
//   },
//   {
//     title: 'Dashboard Teacher',
//     icon: mdiChartPie,
//     resource: 'DashboardTeacher',
//     action: 'read',
//     to: 'dashboard-teacher',
//   },
//   // {
//   //   title: 'Dashboard',
//   //   icon: mdiChartPie,
//   //   children: [
//   //     {
//   //       title: 'Super Admin',
//   //       to: 'dashboard-crm',
//   //     },
//   //     {
//   //       title: 'Admin',
//   //       to: 'dashboard-admin',
//   //     },
//   //     {
//   //       title: 'Teacher',
//   //       to: 'dashboard-teacher',
//   //     },
//   //     {
//   //       title: 'Student',
//   //       to: 'dashboard-student',
//   //     },
//   //   ],
//   // },
// ]
