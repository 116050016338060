const news = [
  {
    path: '/news-admin',
    name: 'news-admin',
    component: () => import('@/views/news/admin/News.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'NewsAdmin',
    },
  },
  {
    path: '/news-school',
    name: 'news-school',
    component: () => import('@/views/news/school/News.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'NewsSchool',
    },
  },
  {
    path: '/news-hod',
    name: 'news-hod',
    component: () => import('@/views/news/hod/News.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'NewsHeadDepartment',
    },
  },
]
export default news
