const lessons = [
  {
    path: '/lesson',
    name: 'lesson-list',
    component: () => import('@/views/lessons/Lesson.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'LessonSchool',
    },
  },
]
export default lessons
