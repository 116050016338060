import http from '@axios'

const resource = 'api/v1/sub-sub-category'

export default {
  list() {
    return http.get(`${resource}`)
  },
  add(subsubcategory) {
    return http.post(`${resource}`, subsubcategory)
  },
  edit(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(subsubcategory, uuid) {
    return http.put(`${resource}/${uuid}`, subsubcategory)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
