export default {
  'Add User': 'Tambah Pengguna',
  'Archive Question Teacher': 'Arsip Soal Guru',
  Admin: 'Admin',
  Announcement: 'Pengumuman',
  Attendance: 'Absensi',
  'Bank Category': 'Bank Kategori',
  'Bank Icon': 'Bank Ikon',
  'Bank Question Teacher': 'Bank Soal',
  Calendar: 'Kalender',
  Category: 'Kategori',
  CBT: 'CBT',
  'Certificate Teacher': 'Sertifikat Guru',
  Company: 'Perusahaan',
  Dashboards: 'Dasbor',
  'Dashboard Admin': 'Dasbor Admin',
  'Dashboard School': 'Dasbor Sekolah',
  'Dashboard Student': 'Dasbor Siswa',
  'Dashboard Teacher': 'Dasbor Guru',
  District: 'Kecamatan',
  'E-Learning': 'E-Learning',
  'E-Learning Teacher': 'E-Learning Guru',
  'E-Learning Parent': 'E-Learning Orang Tua',
  'E-Learning Student': 'E-Learning Siswa',
  'E-Voting': 'E-Voting',
  'E-Voting Teacher': 'E-Voting Guru',
  Functionary: 'Fungsionaris',
  Fund: 'Dana Bos',
  Greeting: 'Sambutan',
  Leaderboard: 'Peringkat',
  Lesson: 'Mata Pelajaran',
  Library: 'Perpustakaan',
  'List User': 'Daftar Pengguna',
  Location: 'Lokasi',
  Majors: 'Jurusan',
  News: 'Berita',
  Packet: 'Paket',
  Province: 'Provinsi',
  Promotion: 'Promosi',
  Regency: 'Kabupaten/Kota',
  Referency: 'Referensi',
  School: 'Sekolah',
  Score: 'Daftar Nilai',
  Student: 'Siswa',
  'Student Attendance': 'Absensi Siswa',
  'Super Admin': 'Super Admin',
  Superadmin: 'Superadmin',
  Teacher: 'Guru',
  Violation: 'Pelanggaran',
  Hod: 'Kepala Dinas',
  Hob: 'Kepala Cabang Dinas',
  FAQ: 'Pertanyaan',
  'Bank Question': 'Bank Soal',
  'Slider Kadis': 'Slider Kepala Dinas',
  Archive: 'Arsip',
  'School Data': 'Data Sekolah',
}
