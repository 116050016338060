export default (time, separator = '-', incTime = true) => {
  const objTime = new Date(time)
  const year = objTime.getFullYear()
  const month = `0${objTime.getMonth() + 1}`
  const date = `0${objTime.getDate()}`
  const hours = objTime.getHours()
  const minutes = objTime.getMinutes()
  if (incTime) {
    return `${hours}.${minutes}, ${date.slice(-2)}${separator}${month.slice(-2)}${separator}${year} `
  }

  return `${hours}:${minutes},${year}${separator}${month.slice(-2)}${separator} ${date.slice(-2)}`
}
