import AbilityRepository from './AbilityRepository'
import AnnouncementHodRepository from './AnnouncementHodRepository'
import AnnouncementRepository from './AnnouncementRepository'
import AnswerCbtRepository from './AnswerCbtRepository'
import AnswerRepository from './AnswerRepository'
import ArchiveBankRepository from './ArchiveBankRepository'
import ArchiveLessonRepository from './ArchiveLessonRepository'
import ArchiveQuestionRepository from './ArchiveQuestionRepository'
import AttendanceClassRepository from './AttendanceClassRepository'
import AttendanceEmployeeRepository from './AttendanceEmployeeRepository'
import AttendanceRepository from './AttendanceRepository'
import AttendanceStudentRepository from './AttendanceStudentRepository'
import AuthRepository from './AuthRepository'
import BankCategoryRepository from './BankCategoryRepository'
import BankIconPostRepository from './BankIconPostRepository'
import BankIconRepository from './BankIconRepository'
import BookmarkForumRepository from './BookmarkForumRepository'
import CalendarRepository from './CalendarRepository'
import CandidateRepository from './CandidateRepository'
import CategoryRepository from './CategoryRepository'
import CbtRepository from './CbtRepository'
import CertificateRepository from './CertificateRepository'
import ChangeClassRepository from './ChangeClassRepository'
import ChapterRepository from './ChapterRepository'
import ClassRepository from './ClassRepository'
import CommentForumRepository from './CommentForumRepository'
import CommentLessonRepository from './CommentLessonRepository'
import CompanyRepository from './CompanyRepository'
import DashboardAdminRepository from './DashboardAdminRepository'
import DashboardSchoolRepository from './DashboardSchoolRepository'
import DashboardTeacherRepository from './DashboardTeacherRepository'
import DistrictRepository from './DistrictRepository'
import EmployeeRepository from './EmployeeRepository'
import EraportRepository from './EraportRepository'
import EvotingRepository from './EvotingRepository'
import ExamRepository from './ExamRepository'
import ExerciseRepository from './ExerciseRepository'
import ExportAttendanceStudentRepository from './ExportAttendanceStudentRepository'
import ExportCbtRepository from './ExportCbtRepository'
import ExportCbtResultRepository from './ExportCbtResultRepository'
import ExportEmployeeRepository from './ExportEmployeeRepository'
import ExportSchoolFundRepository from './ExportSchoolFundRepository'
import ExportStudentRepository from './ExportStudentRepository'
import FaqRepository from './FaqRepository'
import FcmRepository from './FcmRepository'
import ForumRepository from './ForumRepository'
import ForumTopicRepository from './ForumTopicRepository'
import GreetingHeadDepartmentRepository from './GreetingHeadDepartmentRepository'
import GreetingRepository from './GreetingRepository'
import HeadBranchRepository from './HeadBranchRepository'
import HeadDepartmentRepository from './HeadDepartmentRepository'
import HoursRepository from './HoursRepository'
import ImportCbtRepository from './ImportCbtRepository'
import ImportEmployeeRepository from './ImportEmployeeRepository'
import ImportStudentRepository from './ImportStudentRepository'
import LessonRepository from './LessonRepository'
import LevelRepository from './LevelRepository'
import LibraryCategoryRepository from './LibraryCategoryRepository'
import LibraryFavoriteRepository from './LibraryFavoriteRepository'
import LibraryPurchaseRepository from './LibraryPurchaseRepository'
import LibraryRepository from './LibraryRepository'
import LibrarySchoolRepository from './LibrarySchoolRepository'
import LikeForumRepository from './LikeForumRepository'
import MajorRepository from './MajorRepository'
import MajorSchoolRepository from './MajorSchoolRepository'
import MutationGradeRepository from './MutationGradeRepository'
import MutationManyRepository from './MutationManyRepository'
import MutationRepository from './MutationRepository'
import NewsHodRepository from './NewsHodRepository'
import NewsRepository from './NewsRepository'
import NotificationsRepository from './NotificationsRepository'
import PackageSchoolRepository from './PackageSchoolRepository'
import PacketLibraryRepository from './PacketLibraryRepository'
import PacketOrderRepository from './PacketOrderRepository'
import PacketRepository from './PacketRepository'
import PicketScheduleRepository from './PicketScheduleRepository'
import PrintQrClassRepository from './PrintQrClassRepository'
import PrintQrUserRepository from './PrintQrUserRepository'
import PromotionRepository from './PromotionRepository'
import ProvinceRepository from './ProvinceRepository'
import PublisherRepository from './PublisherRepository'
import QrCodeClassRepository from './QrCodeClassRepository'
import QrCodeUserRepository from './QrCodeUserRepository'
import QuestionBankContentRepository from './QuestionBankContentRepository'
import QuestionBankHodRepository from './QuestionBankHodRepository'
import QuestionBankRepository from './QuestionBankRepository'
import QuestionCbtRepository from './QuestionCbtRepository'
import QuestionRepository from './QuestionRepository'
import RankingRepository from './RankingRepository'
import RegencyRepository from './RegencyRepository'
import ResultEvotingRepository from './ResultEvotingRepository'
import ScheduleRepository from './ScheduleRepository'
import SchoolActiveRepository from './SchoolActiveRepository'
import SchoolFundRepository from './SchoolFundRepository'
import SchoolRepository from './SchoolRepository'
import SectionRepository from './SectionRepository'
import SliderHeadDepartmentRepository from './SliderHeadDepartmentRepository'
import SliderRepository from './SliderRepository'
import StudentActiveRepository from './StudentActiveRepository'
import StudentHeadMasterRepository from './StudentHeadMasterRepository'
import StudentRepository from './StudentRepository'
import SubcategoryRepository from './SubcategoryRepository'
import SubjectRepository from './SubjectRepository'
import SubsubcategoryRepository from './SubsubcategoryRepository'
import TagRepository from './TagRepository'
import TeacherActiveRepository from './TeacherActiveRepository'
import TeacherRepository from './TeacherRepository'
import UserRepository from './UserRepository'
import VillageRepository from './VillageRepository'
import ViolationEmployeeRepository from './ViolationEmployeeRepository'
import ViolationForumRepository from './ViolationForumRepository'
import ViolationRepository from './ViolationRepository'
import ViolationSettingRepository from './ViolationSettingRepository'
import ViolationStudentRepository from './ViolationStudentRepository'
import WriterRepository from './WriterRepository'

export default {
  ScheduleRepository,
  AbilityRepository,
  AnswerRepository,
  AnswerCbtRepository,
  AuthRepository,
  ArchiveQuestionRepository,
  ArchiveLessonRepository,
  ArchiveBankRepository,
  AttendanceRepository,
  AttendanceStudentRepository,
  AttendanceClassRepository,
  AttendanceEmployeeRepository,
  BankIconRepository,
  BankIconPostRepository,
  BankCategoryRepository,
  CalendarRepository,
  CategoryRepository,
  CbtRepository,
  CompanyRepository,
  ChapterRepository,
  ChangeClassRepository,
  ClassRepository,
  DistrictRepository,
  ExerciseRepository,
  EmployeeRepository,
  ExportAttendanceStudentRepository,
  ExportCbtRepository,
  ExportCbtResultRepository,
  ExportEmployeeRepository,
  ExportStudentRepository,
  ExportSchoolFundRepository,
  ExamRepository,
  FaqRepository,
  FcmRepository,
  GreetingRepository,
  GreetingHeadDepartmentRepository,
  HoursRepository,
  HeadBranchRepository,
  ImportCbtRepository,
  ImportEmployeeRepository,
  ImportStudentRepository,
  HeadDepartmentRepository,
  LevelRepository,
  LibraryRepository,
  LibraryCategoryRepository,
  LibraryFavoriteRepository,
  LibraryPurchaseRepository,
  LessonRepository,
  MajorRepository,
  MajorSchoolRepository,
  MutationRepository,
  MutationManyRepository,
  MutationGradeRepository,
  NotificationsRepository,
  PacketRepository,
  PacketOrderRepository,
  PacketLibraryRepository,
  PicketScheduleRepository,
  PrintQrUserRepository,
  PrintQrClassRepository,
  ProvinceRepository,
  PromotionRepository,
  PublisherRepository,
  QuestionRepository,
  QuestionCbtRepository,
  QuestionBankRepository,
  QuestionBankContentRepository,
  QrCodeUserRepository,
  QrCodeClassRepository,
  RegencyRepository,
  RankingRepository,
  SchoolRepository,
  SchoolActiveRepository,
  SliderRepository,
  SliderHeadDepartmentRepository,
  StudentRepository,
  StudentActiveRepository,
  SubcategoryRepository,
  SubsubcategoryRepository,
  SubjectRepository,
  SectionRepository,
  TagRepository,
  TeacherRepository,
  TeacherActiveRepository,
  UserRepository,
  VillageRepository,
  WriterRepository,
  EvotingRepository,
  EraportRepository,
  CandidateRepository,
  ResultEvotingRepository,
  ForumTopicRepository,
  ForumRepository,
  CommentForumRepository,
  LikeForumRepository,
  BookmarkForumRepository,
  ViolationRepository,
  ViolationSettingRepository,
  ViolationStudentRepository,
  ViolationEmployeeRepository,
  ViolationForumRepository,
  NewsRepository,
  AnnouncementRepository,
  DashboardSchoolRepository,
  DashboardAdminRepository,
  DashboardTeacherRepository,
  CertificateRepository,
  StudentHeadMasterRepository,
  SchoolFundRepository,
  AnnouncementHodRepository,
  NewsHodRepository,
  QuestionBankHodRepository,
  PackageSchoolRepository,
  CommentLessonRepository,
  LibrarySchoolRepository,
}
