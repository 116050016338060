import http from '@axios'

const resource = 'api/v1/sub-category'

export default {
  list() {
    return http.get(`${resource}`)
  },
  add(subcategory) {
    return http.post(`${resource}`, subcategory)
  },
  edit(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(subcategory, uuid) {
    return http.put(`${resource}/${uuid}`, subcategory)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
