import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/user'
const resourceRegister = '/api/auth/register'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(user, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, user, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  add(user) {
    return http.post(`${resource}`, user)
  },
  register(user) {
    return http.post(`${resourceRegister}`, user)
  },
  addCompany(company) {
    return http.post(`${resourceRegister}`, company)
  },
  addSchool(school) {
    return http.post(`${resourceRegister}`, school)
  },
  addStudent(student) {
    return http.post(`${resourceRegister}`, student)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
