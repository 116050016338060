import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/library-school'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  listParams(uuid, params) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}${paramsString}`)
  },
  add(data) {
    return http.post(`${resource}`, data)
  },
  delete(data) {
    return http.post(`${resource}/delete-package`, data)
  },
}
