import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/attendance-many'
const resourcestudent = '/api/v1/attendance/subject'
const updateresource = '/api/v1/attendance'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${updateresource}${paramsString}`)
  },
  add(attendance) {
    return http.post(`${resource}`, attendance)
  },
  get(uuid) {
    return http.get(`${resourcestudent}/${uuid}`)
  },
  update(attendance, uuid) {
    return http.post(`${updateresource}/${uuid}?_method=PATCH`, attendance)
  },
}
