import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/news'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(news, uuid) {
    return http.post(`${resource}/${uuid}?_method=PUT`, news)
  },
  add(news) {
    return http.post(`${resource}`, news)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
  deleteStorage(image) {
    return http.post(`${resource}/destroy`, image)
  },
}
