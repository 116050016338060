import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/teacher'
const resource2 = '/api/v1/active-teacher'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  listActiveTeacher(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource2}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(teacher, uuid) {
    return http.put(`${resource}/${uuid}`, teacher)
  },
  add(teacher) {
    return http.post(`${resource}`, teacher)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
  correction(answer) {
    return http.post(`${resource}/essay-point`, answer)
  },
}
