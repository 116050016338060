const kadisadmin = [
  {
    path: '/hod',
    name: 'hod-list',
    component: () => import('@/views/hod/Hod.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'HodAdmin',
    },
  },
]
export default kadisadmin
