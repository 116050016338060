import http from '@axios'

const resource = '/api/v1/candidate'

export default {
//   list(params = {}) {
//     const paramsString = paramsConverter(params)

  //     return http.get(`${resource}${paramsString}`)
  //   },
  add(candidate) {
    return http.post(`${resource}`, candidate)
  },
  update(candidate, uuid) {
    return http.put(`${resource}/${uuid}`, candidate)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
