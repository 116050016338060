const packets = [
  {
    path: '/packet',
    name: 'packet-list',
    component: () => import('@/views/packets/Packet.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'PacketAdmin',
    },
  },
]
export default packets
