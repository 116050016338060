const apps = [
  {
    path: '/user/:uuid/profile',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserView',
    },
  },
  {
    path: '/apps/account-settings',
    name: 'apps-account-settings',
    component: () => import('@/views/apps/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserSettings',
    },
  },
  {
    path: '/apps/faq',
    name: 'apps-faq',
    component: () => import('@/views/apps/faq/Faq.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserFaq',
    },
  },
  {
    path: '/apps/notifications',
    name: 'apps-notifications',
    component: () => import('@/views/apps/notifications/Notification.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserNotification',
    },
  },
]
export default apps
