const calendar = [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CalendarSchool',
    },
  },
]
export default calendar
