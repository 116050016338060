import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/library-school'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/package-school${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/package-school/${uuid}`)
  },

}
