const certificate = [
  {
    path: '/certificate-teacher',
    name: 'certificate-teacher',
    component: () => import('@/views/certificates/CertificatesTeacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CertificateTeacher',
    },
  },
]
export default certificate
