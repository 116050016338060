import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const user = JSON.parse(localStorage.getItem('user')) ?? []
const userRole = user && user.user?.role ? user.user?.role : null
let existingAbility = user.user?.ability

// if (userRole === 'admin') {
//   initialAbility.push(...SuperAdminAbility)
// }

// if (existingAbility) {
//   existingAbility = existingAbility.map(item => ({
//     action: item.action,
//     subject: item.subject,
//   }))
//   initialAbility.push(...existingAbility)
// }

if (existingAbility) {
  existingAbility = existingAbility.map(item => ({
    action: item.action,
    subject: item.subject,
  }))
  initialAbility.push(...existingAbility)
}

export default new Ability(initialAbility)
