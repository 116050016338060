import http from '@axios'

const resource = '/api/v1/question-cbt'

export default {
  add(question) {
    return http.post(`${resource}`, question, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(question, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, question, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
