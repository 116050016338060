import http from '@axios'
// import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/archive-question/export-bank'

export default {
  export(archiveBank) {
    return http.post(`${resource}`, archiveBank, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
}
