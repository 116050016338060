import repo from '@/repositories'

const repositories = {
  exportschoolfund: repo.ExportSchoolFundRepository,
  exportattendancestudent: repo.ExportAttendanceStudentRepository,
  exportcbtresult: repo.ExportCbtResultRepository,
}

export default {
  export(service, uuid, params = {}) {
    try {
      return repositories[service.toLowerCase()].export(uuid, params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has export method` })
    }
  },
}
