import http from '@axios'
import paramsConverter from './utils/paramsConverter'
const resource = 'api/v1/library'

export default {
  get(uuid) {
    return http.get(`${resource}/category/${uuid}`)
  },
  getParams(uuid, params = {}) {
    const paramsString = paramsConverter(params)
    return http.get(`${resource}/category/${uuid}${paramsString}`)
  },
}
