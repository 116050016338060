import http from '@axios'

const resource = '/api/v1/class/generate-qr-class'

export default {

  add(generate) {
    return http.post(`${resource}`, generate)
  },
}
