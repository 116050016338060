const locations = [
  {
    path: '/location',
    name: 'location-list',
    component: () => import('@/views/location/Location.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'LocationAdmin',
    },
  },
]

export default locations
