const elearning = [
  // elearning student
  // {
  //   path: '/elearning-student',
  //   name: 'elearning-student',
  //   component: () => import('@/views/elearning/student/Elearning.vue'),
  //   meta: {
  //     layout: 'content',
  //     action: 'read',
  //     resource: 'Elearning',
  //   },
  // },
  // {
  //   path: '/elearning-student/section',
  //   name: 'elearning-student-section',
  //   component: () => import('@/views/elearning/student/ElearningSection.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/elearning-student/section/chapter/lesson',
  //   name: 'elearning-student-section-chapter-lesson',
  //   component: () => import('@/views/elearning/student/ElearningChapter.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/elearning-student/section/chapter/practice',
  //   name: 'elearning-student-section-chapter-practice',
  //   component: () => import('@/views/elearning/student/ElearningPracticeInstruction.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/elearning-student/section/chapter/exercise',
  //   name: 'elearning-student-section-chapter-exercise',
  //   component: () => import('@/views/elearning/student/ElearningExercise.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/elearning-student/section/chapter/result-exercise',
  //   name: 'elearning-student-section-chapter-result-exercise',
  //   component: () => import('@/views/elearning/student/ElearningResultExercise.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // elearning teacher
  {
    path: '/elearning-teacher',
    name: 'elearning-teacher',
    component: () => import('@/views/elearning/teacher/Elearning.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/:uuid',
    name: 'elearning-teacher-subject-section',
    component: () => import('@/views/elearning/teacher/ElearningSection.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },

  {
    path: '/elearning-teacher/subject/section/chapter/lesson/:uuid',
    name: 'elearning-teacher-subject-section-chapter-lesson',
    component: () => import('@/views/elearning/teacher/ElearningLesson.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/lesson-add/:uuid',
    name: 'elearning-teacher-subject-section-chapter-lesson-add',
    component: () => import('@/views/elearning/teacher/ElearningLessonAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/lesson-edit/:uuid',
    name: 'elearning-teacher-subject-section-chapter-lesson-edit',
    component: () => import('@/views/elearning/teacher/ElearningLessonEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exercise-add/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exercise-add',
    component: () => import('@/views/elearning/teacher/ElearningExerciseAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exercise-edit/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exercise-edit',
    component: () => import('@/views/elearning/teacher/ElearningExerciseEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exercise/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exercise',
    component: () => import('@/views/elearning/teacher/ElearningExercise.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exam-add/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exam-add',
    component: () => import('@/views/elearning/teacher/ElearningExamAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exam-edit/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exam-edit',
    component: () => import('@/views/elearning/teacher/ElearningExamEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/section/chapter/exam/:uuid',
    name: 'elearning-teacher-subject-section-chapter-exam',
    component: () => import('@/views/elearning/teacher/ElearningExam.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/exercise/:uuid/result/:uuidStudent',
    name: 'elearning-teacher-section-chapter-result-detail-exercise',
    component: () => import('@/views/elearning/teacher/result/ElearningResultDetailExercise.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-teacher/subject/exam/:uuid/result/:uuidStudent',
    name: 'elearning-teacher-section-chapter-result-detail-exam',
    component: () => import('@/views/elearning/teacher/result/ElearningResultDetailExam.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ElearningTeacher',
    },
  },
  {
    path: '/elearning-parent',
    name: 'elearning-parent',
    component: () => import('@/views/elearning/parent/Elearning.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/elearning-parent/subject',
    name: 'elearning-parent-subject',
    component: () => import('@/views/elearning/parent/ElearningSubject.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/elearning-parent/subject/section',
    name: 'elearning-parent-subject-section',
    component: () => import('@/views/elearning/parent/ElearningSection.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/elearning-parent/subject/section/elearning-result',
    name: 'elearning-parent-subject-section-elearning-result',
    component: () => import('@/views/elearning/parent/ElearningResult.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default elearning
