const greeting = [
  {
    path: '/greeting',
    name: 'greeting-list',
    component: () => import('@/views/greeting/school/Greeting.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'GreetingSchool',
    },
  },
  {
    path: '/greeting-hod',
    name: 'greeting-hod-list',
    component: () => import('@/views/greeting/hod/Greeting.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'GreetingHeadDepartment',
    },
  },
]
export default greeting
