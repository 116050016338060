import repo from '@/repositories'

const repositories = {
  exportstudent: repo.ExportStudentRepository,
  exportemployee: repo.ExportEmployeeRepository,
  exportcbt: repo.ExportCbtRepository,
}

export default {
  export(service, data) {
    try {
      return repositories[service.toLowerCase()].export(data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has export method` })
    }
  },
}
