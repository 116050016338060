import http from '@axios'

const resource = '/api/v1/mutation/add-many'
const resource2 = '/api/v1/mutation/delete-many'

export default {
  add(mutation) {
    return http.post(`${resource}`, mutation)
  },

  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
