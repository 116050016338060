import http from '@axios'

const resource = '/api/v1/answer-cbt'

export default {
  getMultiple(cbtUuid, userUuid) {
    return http.get(`${resource}/${cbtUuid}/user/${userUuid}`)
  },

}
