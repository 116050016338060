const leaderboards = [
  {
    path: '/leaderboard',
    name: 'leaderboard-list',
    component: () => import('@/views/leaderboards/Leaderboard.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default leaderboards
