import http from '@axios'

const resource = '/api/v1/cbt/import'

export default {
  import(uuid, file) {
    return http.post(`${resource}/${uuid}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
  },

}
