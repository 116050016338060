import http from '@axios'

const resource = 'api/v1/cbt/export/result'

export default {
  export(uuid) {
    return http.get(`${resource}/${uuid}`, {
      responseType: 'blob',
      contentType: 'application/pdf',
    })
  },
}
