import repo from '@/repositories'

const repositories = {
  printqruser: repo.PrintQrUserRepository,
  printqrclass: repo.PrintQrClassRepository,
  recapclass: repo.AttendanceClassRepository,
  recapstudent: repo.AttendanceStudentRepository,
}

export default {
  print(service, uuid) {
    try {
      return repositories[service.toLowerCase()].print(uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },
  printRecap(service, uuid, params = {}) {
    try {
      return repositories[service.toLowerCase()].printRecap(uuid, params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },
}
