const attendances = [
  {
    path: '/attendance-school',
    name: 'attendance-school',
    component: () => import('@/views/attendances/school/Attendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AttendanceSchool',
    },
  },
  {
    path: '/attendance-headmaster',
    name: 'attendance-headmaster',
    component: () => import('@/views/attendances/headmaster/Attendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AttendanceHeadMaster',
    },
  },
]
export default attendances
