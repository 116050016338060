const archive = [
  {
    path: '/archive-teacher',
    name: 'archive-teacher',
    component: () => import('@/views/archive/Archive.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ArchiveTeacher',
    },
  },
  {
    path: '/archive-teacher/question/add',
    name: 'archive-question-teacher-add',
    component: () => import('@/views/archive/archive-question/ArchiveQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ArchiveTeacher',
    },
  },

  {
    path: '/archive-teacher/question/edit/:uuid',
    name: 'archive-question-teacher-edit',
    component: () => import('@/views/archive/archive-question/ArchiveQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ArchiveTeacher',
    },
  },

  // Archive Lesson
  {
    path: '/archive-teacher/lesson/add',
    name: 'archive-lesson-teacher-add',
    component: () => import('@/views/archive/archive-lesson/ArchiveLessonAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ArchiveTeacher',
    },
  },
  {
    path: '/archive-teacher/lesson/edit/:uuid',
    name: 'archive-lesson-teacher-edit',
    component: () => import('@/views/archive/archive-lesson/ArchiveLessonEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ArchiveTeacher',
    },
  },
]

export default archive
