import AnswerServices from './AnswerServices'
import ApiServices from './ApiServices'
import AuthServices from './AuthServices'
import ExportParamsServices from './ExportParamsServices'
import ExportServices from './ExportServices'
import FcmServices from './FcmServices'
import ImportParamsServices from './ImportParamsServices'
import ImportServices from './ImportServices'
import MajorServices from './MajorServices'
import NotificationsServices from './NotificationsServices'
import PrintServices from './PrintServices'
import UserServices from './UserServices'

export default {
  AnswerServices,
  AuthServices,
  ApiServices,
  ExportParamsServices,
  ExportServices,
  FcmServices,
  ImportServices,
  ImportParamsServices,
  MajorServices,
  NotificationsServices,
  PrintServices,
  UserServices,
}
