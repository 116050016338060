import http from '@axios'

const resource = 'api/v1/cbt/export/template'

export default {
  export() {
    return http.get(`${resource}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
}
