const book = [
  {
    path: '/book-school',
    name: 'book-school',
    component: () => import('@/views/books/school/Book.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BookSchool',
    },
  },
]
export default book
