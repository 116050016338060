import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/cbt'

export default {
  // list(params = {}) {
  //   const paramsString = paramsConverter(params)

  //   return http.get(`${resource}${paramsString}`)
  // },
  // add(ranking) {
  //   return http.post(`${resource}`, ranking, { headers: { 'Content-Type': 'multipart/form-data' } })
  // },
  getParams(uuid, params = {}) {
    const paramsString = paramsConverter(params)
    return http.get(`${resource}/${uuid}/ranking${paramsString}`)
  },
  // update(ranking, uuid) {
  //   return http.post(`${resource}/${uuid}?_method=PATCH`, ranking, { headers: { 'Content-Type': 'multipart/form-data' } })
  // },
  // destroy(uuid) {
  //   return http.delete(`${resource}/${uuid}`)
  // },
}
