const library = [
  {
    path: '/library',
    name: 'library-list',
    component: () => import('@/views/libraries/Library.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'LibraryAdmin',
    },
  },
]
export default library
