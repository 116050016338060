import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/exam'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(exam) {
    return http.post(`${resource}`, exam)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  getMultiple(examUuid, studentUuid) {
    return http.get(`${resource}/${examUuid}/student/${studentUuid}`)
  },
  update(exam, uuid) {
    return http.put(`${resource}/${uuid}?_method=PATCH`, exam)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
