import http from '@axios'

const resource = '/api/v1/major/school'

export default {

  add(major) {
    return http.post(`${resource}`, major)
  },

  destroy(firstUuid, secondUuid) {
    return http.delete(`major/${firstUuid}/school/${secondUuid}`)
  },
}
