const violations = [
  {
    path: '/violation-school',
    name: 'violation-school',
    component: () => import('@/views/violations/admin/Violation.vue'),
    meta: {
      layout: 'content',
      resource: 'ViolationSchool',
      action: 'read',
    },
  },
  {
    path: '/violation-teacher',
    name: 'violation-teacher',
    component: () => import('@/views/violations/teacher/Violation.vue'),
    meta: {
      layout: 'content',
      resource: 'ViolationTeacher',
      action: 'read',
    },
  },
  {
    path: '/violation-headmaster',
    name: 'violation-headmaster',
    component: () => import('@/views/violations/headmaster/Violation.vue'),
    meta: {
      layout: 'content',
      resource: 'ViolationHeadMaster',
      action: 'read',
    },
  },

]
export default violations
